<template>
  <div class="root">
    <div class="header">
      <div class="title">武侯乐学通平台</div>
    </div>
    <div class="body">
      <div class="left">
        <div class="top">
          <Box>
            <template v-slot:chart>
              <div class="title">
                <i class="el-icon-s-help"></i>

              </div>
              <div class="bar-box">
                <CourseLabelPie v-if="courseLabel.length > 0" :courseLabel="courseLabel" />
              </div>
            </template>
          </Box>
        </div>
        <div class="bottom">
          <Box>
            <template v-slot:chart>
              <div class="title">
                <i class="el-icon-s-help"></i>
                场景趋势图
              </div>
              <div class="bar-box">
                <div class="search">
                  <el-select size="mini" @change="getSiteTimeHot(true)" v-model="site" placeholder="请选择">
                    <el-option v-for="item in siteOptions" :key="item.siteId" :label="item.siteName" :value="item.siteId"> </el-option>
                  </el-select>
                </div>
                <SceneHotLine v-if="siteTimeHot" :siteTimeHot="siteTimeHot" :key="num"/>
              </div>
            </template>
          </Box>
        </div>
      </div>
      <div class="center">
        <div class="top">
          <Box>
            <template v-slot:chart>
              <div class="overview">
                <div v-for="(item, i) in topBaseCount" :key="i" class="item">
                  <h4>
                    {{ item.title }}
                  </h4>
                  <p>
                    {{ item.num }}
                  </p>
                </div>
                <div class="item" v-if="TopOnlineCount.length > 0">
                  <h4>
                    {{ TopOnlineCount[0].title }}
                  </h4>
                  <p>
                    {{ TopOnlineCount[0].num }}
                  </p>
                </div>
              </div>
            </template>
          </Box>
        </div>
        <div class="middle">
          <Box>
            <template v-slot:chart>
              <div class="title">
                <i class="el-icon-s-help"></i>
                场景预约人次统计
              </div>
              <div class="bar-box">
                <SceneRowBar v-if="siteHot" :siteHot="siteHot" />
              </div>
            </template>
          </Box>
        </div>
        <div class="bottom">
          <Box>
            <template v-slot:chart>
              <div class="title">
                <i class="el-icon-s-help"></i>
                热门课程TOP10
              </div>
              <div class="bar-box">
                <CourseRenColbar v-if="courseHot" :courseHot="courseHot" />
              </div>
            </template>
          </Box>
        </div>
      </div>
      <div class="right">
        <div class="top">
          <Box>
            <template v-slot:chart>
              <div class="title">
                <i class="el-icon-s-help"></i>
                课程分类预约人次统计
              </div>
              <div class="bar-box">
                <CourseLabelRadar v-if="labelHot" :labelHot="labelHot" />
              </div>
            </template>
          </Box>
        </div>
        <div class="bottom">
          <Box>
            <template v-slot:chart>
              <div class="title">
                <i class="el-icon-s-help"></i>
                考勤实时统计
              </div>
              <div class="bar-box">
                <div class="search">
                  <el-date-picker size="mini" v-model="date" type="date" :clearable="false" placeholder="选择日期" :picker-options="pickerOptions" @change="getRealTimeAttendanceCount"> </el-date-picker>
                </div>
                <KqBar :kqData="kqData" v-if="kqData" />
              </div>
            </template>
          </Box>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Box from "./components/box";
  import SceneRowBar from "./components/sceneRowBar.vue";
  /*import SceneRowBar2 from "./components/sceneRowBar2.vue";
  import SceneRowBar3 from "./components/sceneRowBar3.vue";*/
  import CourseLabelRadar from "./components/courseLabelRadar.vue";
  import CourseLabelPie from "./components/courseLabelPie.vue";
  import CourseRenColbar from "./components/courseRenColbar.vue";
  import SceneHotLine from "./components/sceneHotLine.vue";
  import KqBar from "./components/kqBar.vue";
  import moment from "moment";
  import { getCourseLabel_api, getRealTimeAttendanceCount_api, getTopBaseCount_api, getCourseHot_api, getSiteHot_api, getSiteTimeHot_api, getTopOnlineCount_api } from "@/api/data.js";
  const  _thq = this ;
  export default {
    components: {
      Box,
      SceneRowBar, // 场景预约人次横向柱状图
      /*   SceneRowBar2, // 场景预约人次横向柱状图
         SceneRowBar3, // 场景预约人次横向柱状图*/
      CourseLabelRadar, // 课程分类预约人次统计
      CourseLabelPie, // 课程分类相应课程数统计
      CourseRenColbar, // 课程预约人次统计
      SceneHotLine, // 场景热度折线图
      KqBar, // 考勤堆叠图
    },
    inject: ["reload"],
    data() {
      return {
        pickerOptions: {
          //控制时间范围
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
        },
        timer: null,
        siteOptions: [], // 场景列表
        site: "", // 场景下拉选择器
        date: new Date(), // 日期选择器默认选中今天
        courseLabel: [], // 课程标签及其课程数
        labelHot: null, // 标签热度
        kqData1: true, // 考勤数据
        kqData:null,
        topBaseCount: [], // 顶部基本数据统计
        courseHot: null, // 课程热度统计
        siteHot: null, // 场景热度统计
        siteTimeHot: null, // 场景时间轴热度
        TopOnlineCount: [], //顶部在线人数统计
        count:1,
        num:1

      };
    },
    methods: {
      // 定时刷新数据
      timeUpdateData() {
        this.timer = setInterval(() => {
          setTimeout(() => {
            this.getTopBaseCount();
          }, 11000);
          setTimeout(() => {
            this.courseLabel = []; // 课程标签及其课程数
            this.getCourseLabel();
          }, 11000);
          setTimeout(() => {
            this.getSiteHot();
          }, 11000);

          setTimeout(() => {
            this.labelHot = null;
            this.getCourseLabel();
          }, 11000);
          setTimeout(() => {
            this.getRealTimeAttendanceCount();
          }, 11000);
          setTimeout(() => {
            this.getCourseHot();
          }, 11000);
          setTimeout(() => {
            this.getSiteTimeHot(true);
          }, 13000);
          setTimeout(() => {
            this.getTopOnlineCount();
          }, 13000);
        }, 60000);
      },
      // 获取场景热度
      async getSiteHot() {
        this.siteHot = null;
         let { code, data, msg } = await getSiteHot_api({
            tenantId: this.$tenantId,
          });
        // let data1=[
        //   {"siteId":"1356452096186712065", "siteName":"华西小学","siteHeat":157},
        //   {"siteId":"1371398015927656449","siteName":"棕北中学西区实验学校","siteHeat":400},
        //   {"siteId":"1371643629882011649","siteName":"锦里小学","siteHeat":311},
        //   {"siteId":"1371647970953097217","siteName":"北二外附小","siteHeat":598},
        //   {"siteId":"1371652529318490113","siteName":"北二外成都附中","siteHeat":334},
        //   {"siteId":"1390279155672682497","siteName":"武侯实验中学","siteHeat":215},
        //   {"siteId":"1390636331025043457","siteName":"川大附中西区学校","siteHeat":208}];

        let data1 = data

        this.siteHot = {};
        this.siteHot.title = [];
        this.siteHot.value = [];
        data1.forEach((item) => {
          if (item.siteHeat > 0) {
            this.siteHot.title.push(item.siteName);
            this.siteHot.value.push(item.siteHeat);
          }
        });
        this.siteOptions = data1;
        this.site = data1[0].siteId;
        this.getSiteTimeHot();
        /*if (code == 200) {
          this.siteHot = {};
          this.siteHot.title = [];
          this.siteHot.value = [];
          data.forEach((item) => {
            if (item.siteHeat > 0) {
              this.siteHot.title.push(item.siteName);
              this.siteHot.value.push(item.siteHeat);
            }
          });
          this.siteOptions = data;
          this.site = data[0].siteId;
          this.getSiteTimeHot();
        } else {
          this.$message.error(msg);
        }*/

      },

      // 获取场景趋势图
      async getSiteTimeHot(bool) {
        this.num++;
        // let datas =[{siteId:'1356452096186712065',siteName:'华西小学',siteHeat:103,siteTimeHot:{title:['2021-05-15','2021-05-22','2021-05-29','2021-06-05','2021-09-04','2021-09-11','2021-10-16','2021-10-23','2021-10-30'],value:[15,15,15,15,20,23,19,17,18]}},
        //   {siteId:"1371398015927656449",siteName:"棕北中学西区实验学校",siteHeat:314,siteTimeHot:{title:['2021-05-15','2021-05-22','2021-05-29','2021-06-05','2021-09-04','2021-09-11','2021-10-16','2021-10-23'],value:[41,57,39,24,60,77,64,38]}},
        //   {siteId:"1371643629882011649",siteName:"锦里小学",siteHeat:308,siteTimeHot:{title:['2021-05-15','2021-05-22','2021-05-29','2021-06-05','2021-09-04','2021-09-11','2021-10-23','2021-10-30'],value:[60,39,50,60,30,30,13,29]}},
        //   {siteId:"1371647970953097217",siteName:"北二外附小",siteHeat:426,siteTimeHot:{title:['2021-05-15','2021-05-22','2021-05-29','2021-09-04','2021-10-16','2021-10-23','2021-10-30'],value:[15,93,145,85,90,89,81]}},
        //   {siteId:"1371652529318490113",siteName:"北二外成都附中",siteHeat:235,siteTimeHot:{title:['2021-05-15','2021-05-29','2021-06-05','2021-09-04','2021-09-11','2021-10-16','2021-10-23','2021-10-30'],value:[98,33,20,30,30,50,33,40]}},
        //   {siteId:"1390279155672682497",siteName:"武侯实验中学",siteHeat:155,siteTimeHot:{title:['2021-05-15','2021-05-22','2021-05-29','2021-09-04','2021-09-11','2021-10-16','2021-10-30'],value:[30,29,31,30,41,27,27]}},
        //   {siteId:"1390636331025043457",siteName:"川大附中西区学校",siteHeat:161,siteTimeHot:{title:['2021-05-16','2021-05-23','2021-05-30','2021-09-04','2021-09-11','2021-10-16','2021-10-23'],value:[30,33,22,19,35,36,33]}}
        // ];
        //
        // if (bool) this.siteTimeHot = null;
        // if (!this.site) return this.$message.error("请选择场景");
        // this.siteTimeHot = {};
        // this.siteTimeHot.title = [];
        // this.siteTimeHot.value = [];
        // datas.filter((x=>{
        //   if(x.siteId==this.site){
        //     this.siteTimeHot = x.siteTimeHot;
        //   }
        // }));
        // console.log(this.siteTimeHot)
        if (bool) this.siteTimeHot = null;
        if (!this.site) return this.$message.error("请选择场景");
        let { code, data, msg } = await getSiteTimeHot_api({
          siteId: this.site,
          tenantId: this.$tenantId,
        });
        if (code == 200) {
          this.siteTimeHot = {};
          this.siteTimeHot.title = [];
          this.siteTimeHot.value = [];
          data.forEach((item) => {
            this.siteTimeHot.title.push(item.countTime);
            this.siteTimeHot.value.push(item.siteHeatByDay);
          });
        } else {
          this.$message.error(msg);
        }
      },
      // 饼状图\雷达图，获取标签及课程数，热度
      async getCourseLabel() {
        let { code, msg, data } = await getCourseLabel_api({
          tenantId: this.$tenantId,
        });

        if (code == 200) {
          this.labelHot = {};
          // this.labelHot.title = [];
          this.labelHot.title = [];
          this.labelHot.value = [];
          let max = 0;
          data.forEach((item) => {
            if (item.tagHeat > max) {
              max = item.tagHeat;
            }
          });
          this.courseLabel = data.map((item) => {
            item.value = item.tagCount;
            item.name = item.tagName;
            this.labelHot.title.push({
              name: item.tagName + "(" + item.tagHeat + ")",
              max: max,
            });
            this.labelHot.value.push(item.tagHeat);
            return item;
          });
        } else {
          this.$message.error(msg);
        }
      },
      // 实时考勤统计
      async getRealTimeAttendanceCount() {
      /*  this.count++
        let unm =  [
          { time:'', data : '{"normal":[0,0,0,0,0,0,0],"overTime":[0,0,0,0,0,0,0],"siteName":["华西小学","棕北中学西区实验学校","锦里小学","北二外成都附中","北二外成都附小","武侯实验中学","四川大学附属中学西区学校"],"truant":[0,0,0,0,0,0,0]}'        }
          ,{ time:'20210515', data : '{"normal":[13,39,58,96,14,28,0],"overTime":[1,2,0,2,1,1,0],"siteName":["华西小学","棕北中学西区实验学校","锦里小学","北二外成都附中","北二外成都附小","武侯实验中学","四川大学附属中学西区学校"],"truant":[1,0,2,0,0,1,0]}'        }
          , { time:'20210516', data : '{"normal":[0,0,0,0,0,0,27],"overTime":[0,0,0,0,0,0,2],"siteName":["华西小学","棕北中学西区实验学校","锦里小学","北二外成都附中","北二外成都附小","武侯实验中学","四川大学附属中学西区学校"],"truant":[0,0,0,0,0,0,1]}'        }
          , { time:'20210522', data : '{"normal":[10,55,30,0,90,28,0],"overTime":[0,1,1,0,2,2,0],"siteName":["华西小学","棕北中学西区实验学校","锦里小学","北二外成都附中","北二外成都附小","武侯实验中学","四川大学附属中学西区学校"],"truant":[0,1,0,0,1,0,0]}'        }
          , { time:'20210523', data : '{"normal":[0,0,0,0,0,0,32],"overTime":[0,0,0,0,0,0,0],"siteName":["华西小学","棕北中学西区实验学校","锦里小学","北二外成都附中","北二外成都附小","武侯实验中学","四川大学附属中学西区学校"],"truant":[0,0,0,0,0,0,1]}'        }
          , { time:'20210529', data : '{"normal":[12,38,47,21,132,24,0],"overTime":[0,1,2,2,2,1,0],"siteName":["华西小学","棕北中学西区实验学校","锦里小学","北二外成都附中","北二外成都附小","武侯实验中学","四川大学附属中学西区学校"],"truant":[0,0,1,0,1,0,0]}'        }
          ,{ time:'20210530', data : '{"normal":[0,0,0,0,0,0,21],"overTime":[0,0,0,0,0,0,1],"siteName":["华西小学","棕北中学西区实验学校","锦里小学","北二外成都附中","北二外成都附小","武侯实验中学","四川大学附属中学西区学校"],"truant":[0,0,0,0,0,0,0]}'        }
          , { time:'20210605', data : '{"normal":[0,0,0,0,0,0,21],"overTime":[0,0,0,0,0,0,1],"siteName":["华西小学","棕北中学西区实验学校","锦里小学","北二外成都附中","北二外成都附小","武侯实验中学","四川大学附属中学西区学校"],"truant":[0,0,0,0,0,0,0]}'        }
          , { time:'20210904', data : '{"normal":[17,26,13,29,39,26,22],"overTime":[2,2,1,1,3,2,1],"siteName":["华西小学","棕北中学西区实验学校","锦里小学","北二外成都附中","北二外成都附小","武侯实验中学","四川大学附属中学西区学校"],"truant":[1,2,1,0,1,2,3]}'        }
          , { time:'20210911', data : '{"normal":[18,49,24,28,0,22,31],"overTime":[1,2,0,0,0,1,1],"siteName":["华西小学","棕北中学西区实验学校","锦里小学","北二外成都附中","北二外成都附小","武侯实验中学","四川大学附属中学西区学校"],"truant":[1,2,3,2,0,2,1]}'        }
        ];

        let vm= this;
        this.kqData = [];
        let vt1 =  moment(this.date).format("YYYYMMDD");
        if(unm.filter(x=>x.time==vt1).length>0){
          vm.kqData= JSON.parse( unm.filter(x=>x.time==vt1)[0].data)
        }else{
          vm.kqData= JSON.parse(unm[0].data)
        }*/
        this.kqData = null;
        let { code, data, msg } = await getRealTimeAttendanceCount_api({
          dateStr: moment(this.date).format("YYYY-MM-DD"),
          tenantId: this.$tenantId,
        });
        if (code == 200) {
          this.kqData = data;
        } else {
          this.$message.error(msg);
        }

      },
      //顶部在线人数统计
      async getTopOnlineCount() {
        let a = await getTopOnlineCount_api({
          tenantId: this.$tenantId,
        });
        this.TopOnlineCount.push({
          title: "在线人次",
          num: a,
        });
      },
      // 顶部基本数据统计
      async getTopBaseCount() {
        this.topBaseCount = []; // 顶部基本数据统计
        let { code, data, msg } = await getTopBaseCount_api({
          tenantId: this.$tenantId,
        });
        if (code == 200) {
          this.topBaseCount.push({
            title: "场景数",
            num: 12,
            // num: data.siteNum,
          });
          this.topBaseCount.push({
            title: "课程数",
            // num:21,
            num:data.courseNum,
          });
          this.topBaseCount.push({
            title: "累计开课数",
            // num: 165,
            num:data.teachRecordNum,

          });
          this.topBaseCount.push({
            title: "累计预约人次",
            //num: 5049,
            num: data.reserveCourseNum-297,
            //预约数未排除其他预约状态 select count(*) from t_reserve_info where is_deleted=0 and reserve_status in (1,5) ,真实数据排出后还需减391人
          });
        } else {
          this.$message.error(msg);
        }
      },
      // 课程热度统计（预约人次）
      async getCourseHot() {
        this.courseHot = null; // 课程热度统计
        let { code, data, msg } = await getCourseHot_api({
          tenantId: this.$tenantId,
        });
        if (code == 200) {
          this.courseHot = {};
          this.courseHot.title = [];
          this.courseHot.value = [];
          // let num =60
          data.forEach((element) => {
            // if(num>57) num--
            // else if(num==57) num -= 2
            // else if(num == 55) num--
            // else if(num >53) num -=3
            // else if(num >48) num --
            this.courseHot.title.push(element.courseName);
            this.courseHot.value.push(element.courseHeat);
            // this.courseHot.value.push(num);
          });
        } else {
          this.$message.error(msg);
        }
      },
    },
    created() {
      this.getSiteHot();
      this.getCourseLabel();
      this.getRealTimeAttendanceCount();
      this.getTopBaseCount();
      this.getCourseHot();
      this.timeUpdateData();
      this.getTopOnlineCount();
    },
    mounted() {
      window.addEventListener("resize", this.reload,true);
    },
    beforeDestroy() {
      clearInterval(this.timer);
      window.removeEventListener("resize", this.reload);
    },
  };
</script>

<style lang="less" scoped>
  .root {
    height: 100%;
    box-sizing: border-box;
    background-image: url("../../assets/img/dataBc.png");
    .header {
      height: 100px;
      box-sizing: border-box;
      background-image: url("../../assets/img/data1.svg");
      .title {
        margin: 0 auto;
        box-sizing: border-box;
        width: 900px;
        line-height: 100px;
        background-image: url("../../assets/img/data2.svg");
        text-align: center;
        color: #1acdfd;
        font-size: 40px;
        font-weight: 500;
        letter-spacing: 5px;
      }
    }
    .body {
      height: calc(100% - 100px);
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      .left,
      .right {
        width: 28%;
        height: 100%;
        box-sizing: border-box;
        .top,
        .bottom {
          height: calc(50% - 5px);
          box-sizing: border-box;
        }
        .top {
          margin-bottom: 10px;
        }
      }
      .center {
        width: calc(44% - 20px);
        margin: 0 10px;
        height: 100%;
        box-sizing: border-box;
        .top {
          height: calc(16% - 20px);
          box-sizing: border-box;
          .overview {
            padding: 20px;
            height: 100%;
            display: flex;
            justify-content: space-between;
            .item {
              width: 19%;
              height: calc(100% - 40px);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              color: #fff;
              h4 {
                font-size: 14px;
                margin-bottom: 8px;
              }
              p {
                font-weight: 700;
                font-size: 22px;
              }
              &:nth-child(1) {
                background-color: #007afe;
              }
              &:nth-child(2) {
                background-color: #fec03d;
              }
              &:nth-child(3) {
                background-color: #52c1f5;
              }
              &:nth-child(4) {
                background-color: #fb6260;
              }
              &:nth-child(5) {
                background-color: #58a3f7;
              }
            }
          }
        }
        .middle {
          height: 42%;
          margin: 10px 0;
          box-sizing: border-box;
        }
        .bottom {
          height: 42%;
          box-sizing: border-box;
        }
      }
      .title {
        box-sizing: border-box;
        line-height: 40px;
        margin: 20px 0;
        font-weight: 700;
        font-size: 18px;
        color: #b4e4ff;
        display: inline-block;
        padding: 0 20px;
        border-top: 1px solid #1878d1;
        border-bottom: 1px solid #1878d1;
        border-right: 1px solid #1878d1;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      .bar-box {
        height: calc(100% - 80px);
        position: relative;
        .search {
          position: absolute;
          top: -53px;
          right: 10px;
          z-index: 9;
        }
      }
      /deep/.el-input__inner {
        background-color: rgba(0, 0, 0, 0);
        color: #00c7ff;
        border-color: #00c7ff;
        width: 310px;
      }
      /deep/.el-select__caret {
        color: #00c7ff;
      }
      /deep/.el-date-editor {
        width: 200px;
      }
      .el-date-editor /deep/.el-input__inner {
        width: 200px;
      }
      /deep/.el-input__prefix {
        color: #00c7ff;
      }
    }
  }
</style>


<template>
  <div class="box-root">
    <span class="left_top"></span>
    <span class="left_bottom"></span>
    <span class="right_top"></span>
    <span class="right_bottom"></span>
    <div class="chart-box">
      <slot name="chart" />
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="less" scoped>
.box-root {
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid #064a9a;
  .chart-box {
    height: 100%;
  }
  span {
    padding: 6px;
    position: absolute;
    &.left_top {
      top: -1px;
      left: -1px;
      border-left: 2px solid #007afe;
      border-top: 2px solid #007afe;
    }
    &.left_bottom {
      bottom: -1px;
      left: -1px;
      border-left: 2px solid #007afe;
      border-bottom: 2px solid #007afe;
    }
    &.right_top {
      top: -1px;
      right: -1px;
      border-right: 2px solid #007afe;
      border-top: 2px solid #007afe;
    }
    &.right_bottom {
      bottom: -1px;
      right: -1px;
      border-right: 2px solid #007afe;
      border-bottom: 2px solid #007afe;
    }
  }
}
</style>